import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ArrowNextCircleIcon, ArrowPrevCircleIcon } from "../../components/Icons"
import ProductCard from "../../components/ProductCard"
import BreakpointUp from "../../components/Media/BreakpointUp"
import { v4 } from 'uuid'

const SlickItem = styled.div`
  display:block !important;
  outline:none;
  max-width:100%;
  padding:20px 10px;
  @media (min-width: 1366px){
    padding:30px 20px; 
  }
  .card{
    &:before {
      width: 50%;
      height: 50%;
    }
    .sku{
      background-size:100%;
      font-size: 14px;
      left: -26px;
      strong{
        font-size: 14px;
        font-weight: 600;
      }
    }
    .card-img{
      margin: 30px 15px 15px;
      .overlay{
        padding: 10px 10px 5px;
        strong{
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
    .card-title{
      font-weight:600;
    }
    .card-body{
      padding: 15px 20px;
    }
    .finance-option{
      top:5px;
    }
    .measurement{
      margin:0 -15px 40px;
      li{
        margin-bottom:0;
        ${BreakpointUp.xl`
          padding:0 15px;
        `}
        .icon{
          svg{
            width: 40px;
          }   
        }
      }
    }
    .card-footer{
      padding: 0 15px 15px 15px;
    }
  }
`
const SlickSlider = styled.div`
  padding:0;

`
const SlickArrowTrack = styled.div`
  position: absolute;
  top:100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 160px;
  width: 100%;  
  &:hover {
    cursor: pointer; 
  }
`
const LeftArrow = styled.div`
  position: absolute;  
  width: 60px;
  height: 42px;
  left:0;
  svg{
    fill:#CFCFCF;
  }
  &:hover {
    svg{
      fill:#0E0F0F;
    }  
  }
`
const RightArrow = styled.div`
  position: absolute;  
  width: 60px;
  height: 42px;
  right:0; 
  svg{
    fill:#CFCFCF;
  }
  &:hover {
    svg{
      fill:#0E0F0F;
    }  
  } 
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
      aria-hidden="true"
    ><ArrowNextCircleIcon/></div>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
      aria-hidden="true"
    ><ArrowPrevCircleIcon/></div>
  );
}

export default class RelatedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { intl, data, openQuoteModal } = this.props
    const settings = {
      infinite: true,
      slidesToShow: 3,
      speed: 500,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            arrows:false,
            centerMode: true,
            centerPadding: '60px',            
            infinite: true

          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
          }
        }
      ]     
    };
    return (      
      <SlickSlider>       
        <Slider  {...settings} ref={c => (this.slider = c)}>
          {
            data.map(item => (
              <SlickItem key={v4()}>
                <ProductCard data={item.node} intl={intl} openQuoteModal={openQuoteModal} />
              </SlickItem>   
            ))
          }
        </Slider>
        
        <SlickArrowTrack>
          <LeftArrow onClick={this.previous}>
              <ArrowPrevCircleIcon fill="#020304" />
          </LeftArrow>
          <RightArrow onClick={this.next}>
            <ArrowNextCircleIcon fill="#020304" />
          </RightArrow>
        </SlickArrowTrack>
      </SlickSlider>
    );
  }
}
