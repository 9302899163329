import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
} from "../components/Section"
import {
  PhoneIcon,
  GetQuoteIcon,
  WidthIcon,
  LenghtIcon,
  HeightIcon,
  AvailableIcon,
  BulletIcon,
} from "../components/Icons"
import ColorCard from "../components/ColorCard"
import RelatedProductCarousel from "../components/RelatedProductCarousel"
import PrimaryIconButton from "../components/Button/PrimaryIconButton"
import DefaultButton from "../components/Button/DefaultButton"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import QuotePopup from "../components/QuotePopup"
import SkuBg from "../images/sku-bg.svg"
import { v4 } from "uuid"

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.xl`
    flex-direction: row-reverse;
  `}
  &:before, &:after {
    content: "";
    position: absolute;
    transform: matrix(-0.87, 0.5, -0.5, -0.87, 0, 0);
    display: none;
    ${BreakpointUp.xl`
            display:block;
        `}
  }
  &:before {
    width: 82px;
    height: 82px;
    border: 8px solid #fff;
    top: -40px;
    right: 35px;
  }
  &:after {
    width: 50px;
    height: 50px;
    border: 5px solid #211f28;
    top: -65px;
    right: 120px;
  }
`
const ProductLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${BreakpointUp.xl`
    padding-left: 85px;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `}
`
const ProductRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.xl`
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `}
`

const ProductTitle = styled.h1`
  display: block;
  margin: 0 0 40px;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 34px;
    line-height: 44px;
  }
  @media (min-width: 992px) {
    font-size: 44px;
    line-height: 50px;
  }
`

const Product = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 30px;
  ${BreakpointDown.sm`		
        margin:0 -15px 30px -15px;
	`}
`

const ProductFigure = styled.div`
  position: relative;
  width: 100%;
  & .gatsby-image-wrapper {
    height: 100%;
  }
`

const ProductBody = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.1);
  margin: -20px 15px 0 15px;
  padding: 20px 15px;
  ${BreakpointUp.md`		
        margin:-50px 50px 0 50px;
        padding: 20px 30px;  
	`}
`
const ProductPriceGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ProductPrice = styled.div`
  small {
    font-size: 18px;
    line-height: 32px;
    color: #6a6a6a;
    display: block;
    font-weight: 600;
  }
  strong {
    display: block;
    font-family: "Rajdhani", sans-serif;
    color: #211f28;
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 30px;
    line-height: 34px;
    @media (min-width: 768px) {
      font-size: 34px;
      line-height: 44px;
    }
    @media (min-width: 992px) {
      font-size: 44px;
      line-height: 50px;
    }
  }
`
// const ProductDownPrice = styled.div`
//   small {
//     color: #6a6a6a;
//     display: block;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 32px;
//     @media (min-width: 768px) {
//       font-size: 18px;
//     }
//   }
//   strong {
//     display: block;
//     font-family: "Rajdhani", sans-serif;
//     color: #211f28;
//     font-weight: 700;
//     margin: 0 0 10px;
//     font-size: 24px;
//     line-height: 30px;
//     text-align: right;
//     @media (min-width: 768px) {
//       font-size: 26px;
//     }
//     @media (min-width: 992px) {
//       font-size: 34px;
//       line-height: 44px;
//     }
//   }
// `

const HelpBlock = styled.div`
  position: relative;
  color: #6a6a6a;
  line-height: 20px;
  font-size: 10px;
  margin-bottom: 20px;
  text-align: center;
  padding-bottom: 15px;
  ${BreakpointUp.sm`    
        font-size:12px;
        text-align:left;
        padding-bottom:0;
    `}
  span {
    background-color: #fff;
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, #707070 50%, #fff 0%);
    background-position: 0 0;
    background-size: 9px 1px;
    background-repeat: repeat-x;
    width: 100%;
    height: 1px;
    ${BreakpointUp.sm`    
            top: 50%;
        `}
  }
`
const ButtonToolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -5px;
  ${BreakpointUp.sm`    
        margin:0 -10px;
    `}
  a {
    padding: 0 5px;
    ${BreakpointUp.sm`    
            padding:0 10px;
        `}
  }
  .btn {
    ${BreakpointDown.sm`    
            font-size:14px;  
        `}
    .icon {
      > svg {
        ${BreakpointDown.sm`    
                    width:14px;
                `}
      }
    }
  }
`
const ProductBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${BreakpointDown.sm`    
        flex-direction: column;
        justify-content: center;
    `}
`

const SkuBadge = styled.div`
  font-family: "Rajdhani", sans-serif;
  background-image: ${props => props.bg};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50% 50%;
  position: absolute;
  text-align: center;
  color: #fff;
  top: -12px;
  left: -10px;
  padding: 12px 0;
  width: 306px;
  height: 56px;
  z-index: 1;
  ${BreakpointDown.sm`
        font-size: 13px;	
        top: -10px;
        left: 0;
        padding: 5px 0px;
        width: 210px;
        height: 40px;
        background-size: cover;        
    `}
  strong {
    font-weight: 700;
    font-size: 13px;
    ${BreakpointUp.sm`		
            font-size:16px;            
        `}
  }
`
const ProductSize = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -5px;
  justify-content: space-evenly;
  ${BreakpointUp.sm`		
        margin:0 -20px;
        justify-content:left;
    `}
`
const ProductItemSize = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px;
  ${BreakpointUp.sm`	
        padding:0 20px;
    `}
  .icon {
    display: inline-flex;
    svg {
      width: 40px;
      ${BreakpointUp.sm`		
            width:auto;
            `}
    }
    + .text {
      margin-left: 5px;
      ${BreakpointUp.lg`		
                margin-left:10px;                
            `}
    }
  }
  .text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    small {
      color: #6a6a6a;
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      ${BreakpointUp.sm`		
                font-size:14px;              
            `}
    }
    strong {
      display: block;
      font-family: "Rajdhani", sans-serif;
      font-weight: 700;
      color: #211f28;
      font-size: 20px;
      line-height: 24px;
      ${BreakpointUp.sm`		
                font-size:26px;
                line-height: 26px;           
            `}
    }
  }
`

const FinanceOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Rajdhani", sans-serif;
  line-height: 20px;
  color: #1b1a21;
  font-weight: 600;
  margin: 0 -15px 10px -15px;
  ${BreakpointUp.sm` 
        margin:0 -15px;
    `}
`
const FinanceItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 5px;
  ${BreakpointUp.sm` 
        padding:0 15px;        
    `}
  .icon {
    > svg {
      fill: #52a400;
      ${BreakpointDown.sm` 
                font-size:14x;
            `}
    }
    + .text {
      margin-left: 6px;
    }
  }
  .text {
    font-size: 12px;
    line-height: 18px;
    ${BreakpointUp.sm`  
            line-height:20px;               
            font-size:16px;
        `}
  }
`

const ProductFeature = styled.div`
  padding: 0;
`
const ProductFeatureItem = styled.div`
  background-image: linear-gradient(to right, #cfcfcf 50%, #fff 0%);
  background-position: bottom center;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  ${BreakpointUp.md`
        padding-bottom:40px;
        margin-bottom:40px;
    `}
  &:last-child {
    background: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  h2 {
    font-size: 18px;
    line-height: 24px;
    ${BreakpointUp.md`
            font-size: 20px;
            line-height: 26px;
        `}
    ${BreakpointUp.lg`
            font-size: 26px;
            line-height: 30px;
        `}
  }
`
const TableStriped = styled.div`
  border: 1px solid #cfcfcf;
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  ${BreakpointUp.md`
        font-size:16px;
        line-height:26px;
    `}
`
const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #cfcfcf;
  &:nth-child(even) {
    background-color: #f6f6f6;
  }
  &:last-child {
    border-bottom: none;
  }
`
const TableCol = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 10px;
  ${BreakpointUp.md`
        padding:10px 15px;
    `}
  strong {
    color: #211f28;
    font-weight: 700;
  }
`

function ProductPage({ data, intl }) {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const pageData = data.contentfulProduct
  const colorData = data.contentfulColorPage.colors
  const allProducts = data.allContentfulProduct.edges
  let relatedProducts = []
  allProducts.forEach(item => {
    if (
      item.node.productCategory.name === pageData.productCategory.name &&
      item.node.productName !== pageData.productName
    ) {
      relatedProducts.push(item)
    }
    return true
  })

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: pageData.productName,
    image: pageData.image?.fluid?.src,
    description: pageData.productDescription?.productDescription,
    mpn: "MPN",
    SKU: pageData.productSku,
    brand: {
      "@type": "Thing",
      name: "Global Carports",
    },
    offers: {
      "@type": "Offer",
      url: `https://www.globalcarport.com/en${pageData.url}`,
      priceValidUntil: "2021-12-31",
      priceCurrency: "USD",
      price: pageData.startingPrice,
      itemCondition: "NewCondition",
      availability: "InStock",
      seller: {
        "@type": "Organization",
        name: "Global Carports",
      },
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
      },
      author: {
        "@type": "Person",
        name: "GC",
      },
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.3",
      reviewCount: "28",
    },
    category: `<span>${pageData.productCategory.name}</span>`,
    material: "14 GA Steel",
    width: {
      "@type": "QuantitativeValue",
      value: "36",
      unitcode: "FOT",
    },
    depth: {
      "@type": "QuantitativeValue",
      value: "30",
      unitcode: "FOT",
    },
    height: {
      "@type": "QuantitativeValue",
      value: "12",
      unitcode: "FOT",
    },
  }
  return (
    <Layout>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        schemaMarkup={schema}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to="/">
            {pageData.productCategory.name} <BulletIcon />
          </Link>
          <span>{pageData.productName}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="90px"
        xpb="60px"
        bgColor="#F6F6F6"
        bdrBottom="#D8D8D8"
      >
        <div className="container">
          <ProductTitle>{pageData.productName}</ProductTitle>
          <ProductGrid>
            <ProductLeft>
              <Product>
                <SkuBadge bg={`url(${SkuBg})`}>
                  SKU : <strong>{pageData.productSku}</strong>
                </SkuBadge>
                <ProductFigure>
                  {pageData.image?.fluid && <Img
                    fluid={pageData.image.fluid}
                    alt={pageData.image.description}
                  />}
                </ProductFigure>
                <ProductBody>
                  <ProductPriceGrid>
                    <ProductPrice>
                      <small>
                        {intl.formatMessage({ id: "Starting Price" })}
                      </small>
                      <strong>${pageData.startingPrice}*</strong>
                    </ProductPrice>
                    {/* {pageData.rto ? (
                      <ProductDownPrice>
                        <small>
                          {intl.formatMessage({ id: "Min. Down Payment" })}
                        </small>
                        <strong>
                          $
                          {pageData.startingPrice *
                            process.env.GATSBY_DOWN_PRICE_PERCENT}
                        </strong>
                      </ProductDownPrice>
                    ) : (
                      <></>
                    )} */}
                  </ProductPriceGrid>
                  <HelpBlock>
                    <span>
                      *
                      {intl.formatMessage({
                        id:
                          "Price varies by states and certification requirements",
                      })}
                    </span>
                  </HelpBlock>
                  <ProductBottom>
                    <FinanceOption>
                      {pageData.rto ? (
                        <FinanceItem>
                          <span className="icon">
                            <AvailableIcon />
                          </span>
                          <span className="text">
                            {intl.formatMessage({ id: "RTO" })}
                          </span>
                        </FinanceItem>
                      ) : (
                        <></>
                      )}
                      {pageData.financing ? (
                        <FinanceItem>
                          <span className="icon">
                            <AvailableIcon />
                          </span>
                          <span className="text">
                            {intl.formatMessage({ id: "Financing" })}
                          </span>
                        </FinanceItem>
                      ) : (
                        <></>
                      )}
                    </FinanceOption>
                    <ButtonToolbar>
                      <a href="tel:8009187432">
                        <PrimaryIconButton
                          icon={<PhoneIcon />}
                          text="(800) 918-7432"
                        />
                      </a>
                      <button
                        aria-label="get a quote"
                        className="btn"
                        onClick={() => setQuoteVisible(true)}
                      >
                        <DefaultButton
                          icon={<GetQuoteIcon />}
                          text={intl.formatMessage({ id: "Get a quote" })}
                        />
                      </button>
                    </ButtonToolbar>
                  </ProductBottom>
                </ProductBody>
              </Product>
            </ProductLeft>
            <ProductRight>
              <ProductFeature>
                <ProductFeatureItem>
                  <ProductSize className="measurement">
                    <ProductItemSize>
                      <span className="icon">
                        <WidthIcon />
                      </span>
                      <span className="text">
                        <small>{intl.formatMessage({ id: "Width" })}</small>
                        <strong>{pageData.width}</strong>
                      </span>
                    </ProductItemSize>
                    <ProductItemSize>
                      <span className="icon">
                        <LenghtIcon />
                      </span>
                      <span className="text">
                        <small>{intl.formatMessage({ id: "Length" })}</small>
                        <strong>{pageData.length}</strong>
                      </span>
                    </ProductItemSize>
                    <ProductItemSize>
                      <span className="icon">
                        <HeightIcon />
                      </span>
                      <span className="text">
                        <small>{intl.formatMessage({ id: "Height" })}</small>
                        <strong>{pageData.height}</strong>
                      </span>
                    </ProductItemSize>
                  </ProductSize>
                </ProductFeatureItem>

                <ProductFeatureItem>
                  <h2>{intl.formatMessage({ id: "Description" })}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        pageData.productDescription?.childMarkdownRemark.html,
                    }}
                  />
                </ProductFeatureItem>
                <ProductFeatureItem>
                  <h2>
                    {intl.formatMessage({ id: "Building Specifications" })}
                  </h2>
                  <p>
                    {intl.formatMessage({
                      id: "Fully enclosed garage all vertical",
                    })}
                  </p>
                  <TableStriped>
                    {pageData.specifications && pageData.specifications.map(item => (
                      <TableRow key={v4()}>
                        <TableCol>{item.spec}</TableCol>
                        <TableCol>
                          <strong>{item.value}</strong>
                        </TableCol>
                      </TableRow>
                    ))}
                  </TableStriped>
                </ProductFeatureItem>
                <ProductFeatureItem>
                  <h2>{intl.formatMessage({ id: "Available Colors" })}</h2>
                  <ColorCard data={colorData} />
                </ProductFeatureItem>
              </ProductFeature>
            </ProductRight>
          </ProductGrid>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="90px" bg="#fff">
        <div className="container">
          <SectionTitle>
            {intl.formatMessage({ id: "Related Products" })}
          </SectionTitle>
          <SectionDescription maxWidth="1120px" mb="20px"></SectionDescription>
          <RelatedProductCarousel
            intl={intl}
            data={relatedProducts}
            openQuoteModal={() => setQuoteVisible(true)}
          />
        </div>
      </Section>
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
    </Layout>
  )
}

export default injectIntl(ProductPage)

export const query = graphql`
  query ProductPageQuery($slug: String, $locale: String) {
    contentfulProduct(url: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      url
      metaTitle
      metaDescription
      productSku
      productName
      productDescription {
        childMarkdownRemark {
          html
        }
        productDescription
      }
      featuredProduct
      productCategory {
        name
      }
      productSubcategory {
        name
      }
      productRoof {
        name
      }
      image {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      width
      length
      height
      startingPrice
      rto
      financing
      specifications {
        spec
        value
      }
      otherDetails {
        childMarkdownRemark {
          html
        }
      }
      freeDelivery
    }
    contentfulColorPage(node_locale: { eq: $locale }) {
      colors {
        title
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
    }
    allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          node_locale
          productSku
          productName
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
          }
          productSubcategory {
            name
          }
          productRoof {
            name
          }
          image {
            fluid(quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          width
          length
          height
          startingPrice
          rto
          financing
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          freeDelivery
        }
      }
    }
  }
`
