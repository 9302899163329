import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import Img from "gatsby-image"
import BreakpointUp from "../Media/BreakpointUp"

const ListColorCard = styled.ul`
  list-style: none;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-left: -6px;
`
const ColorItem = styled.li`
  margin-bottom: 12px;
  text-align: center;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 6px;
  padding-left: 6px;
  flex: 0 0 50%;
  max-width: 50%;
  ${BreakpointUp.sm`   
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
  ${BreakpointUp.lg`   
        flex: 0 0 20%;
        max-width: 20%;
    `}
`
const ColorCardWrapper = styled.div`
  display: block;
  border: 1px solid #cfcfcf;
  background-color: #fff;
  padding: 5px;
`
const ColorFigure = styled.figure`
  display: flex;
  margin-bottom: 0;
  .gatsby-image-wrapper {
    width: 100%;
  }
`
const ColorName = styled.span`
  display: block;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  color: #6a6a6a;
`

const ColorCard = ({ data }) => {
  return (
    <ListColorCard className="list-color">
      {data.map(item => (
        <ColorItem className="color-item" key={v4()}>
          <ColorCardWrapper className="card">
            <ColorFigure>
              <Img fluid={item.fluid} alt={item.description} />
            </ColorFigure>
            <ColorName className="color">{item.title}</ColorName>
          </ColorCardWrapper>
        </ColorItem>
      ))}
    </ListColorCard>
  )
}

export default ColorCard
